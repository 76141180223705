<template>
  <div class="guide">
    <div v-html="zn.content"></div>
  </div>
</template>

<script>
import request from "../../api/request";
export default {
  data() {
    return {
      zn: "",
    };
  },
  mounted() {
    this.getzn();
  },
  methods: {
    getzn() {
      let _this = this;
      request.get("/terms/5", {}).then(function (res) {
        if (res.status == 200) {
          _this.zn = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.guide {
  margin: 50px 0;
}
</style>
