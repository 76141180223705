<template>
  <div class="integralshop">
    <div class="cont">
      <div class="cont-title">
        <div class="font-w">panggame{{ $t("积分商城") }}</div>
        <div class="tab-bar">
          <span
            @click="tab(index)"
            v-for="(item, index) in navList"
            :key="index"
            :class="{ shopactive: index == navIndex }"
          >
            <span v-if="index === 0 || index === 2">{{ item.name }}</span>
            <span v-else>
              <a-dropdown>
                <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                  {{ $t("查看内容") }}<a-icon type="down" />
                </a>
                <a-menu slot="overlay" @click="onClick">
                  <a-menu-item key="1">
                    {{ $t("查看积分记录") }}
                  </a-menu-item>
                  <a-menu-item key="2">
                    {{ $t("查看兑换记录") }}
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="center">
        <div class="centerL">
          <p>{{ text }}</p>
          <p>{{ cont }}</p>
        </div>
        <div class="centerR">
          <p>{{ $t("我的积分") }}</p>
          <p>
            <span>{{ originPriceFormat(userpoint) }}</span
            >POINT
          </p>
        </div>
      </div>
    </div>
    <div class="tab-cont">
      <div v-if="navIndex === 0">
        <Commodity></Commodity>
      </div>
      <div v-else-if="navIndex === 1">
        <Record :choseId="choseId"></Record>
      </div>
      <div v-else>
        <Guide></Guide>
      </div>
    </div>
  </div>
</template>

<script>
import Commodity from "./Commodity";
import Guide from "./Guide";
import Record from "./Record";
import request from "../../api/request";
import Bus from "../../utils/bus";
import filters from "../../utils/filters";
export default {
  components: {
    Commodity,
    Guide,
    Record,
  },
  data() {
    return {
      navIndex: 0,
      text: this.$t("积分商城"),
      cont: this.$t(
        "panggame通过各种活动来支付PANG积分，将手机的积分与新介绍的丰富多样的优惠。"
      ),
      navList: [
        {
          name: this.$t("商品交换"),
        },
        {
          name: this.$t("查看内容"),
        },
        // {
        //   name: this.$t("积分使用指南"),
        // },
      ],
      choseId: 1,
      userpoint: "",
    };
  },
  beforeCreate() {
    var query = this.$route.query;
    let token = query.token;
    if (token) {
      this.$store.commit("setToken", token);
      this.$router.replace({
        path: this.$route.path,
      });
    }
  },
  mounted() {
    if (this.$store.state.token != "null") {
      this.getMe();
    }
    let gettoken = window.localStorage.getItem("token");
    if (gettoken == "null") {
      this.$router.push("/login");
    }
  },
  methods: {
    originPriceFormat: filters.priceFormat,
    tab(index) {
      this.navIndex = index;
    },
    onClick({ key }) {
      this.choseId = key;
      this.navIndex = 1;
    },
    getMe() {
      let _this = this;
      request.get("/users/me", {}, true).then(function (res) {
        if (res.status == 200) {
          _this.userpoint = res.data.member_point;
          if (res.data) {
            _this.$store.commit("setUserInfo", res.data);
          }
        }
      });
    },
  },
  created() {
    Bus.$on("getuserpoint", () => {
      this.getMe();
    });
  },
};
</script>

<style lang="scss">
.integralshop {
  .cont {
    background-color: #252a2f;
    color: #fff;
    height: 80px;
    line-height: 80px;
    .cont-title {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      .tab-bar {
        display: flex;
        span {
          font-size: 20px;
          cursor: pointer;
          white-space: nowrap;
          display: inline-block;
          padding: 0 10px;
          @media only screen and (max-width: 900px) {
            padding-right: 5px;
          }
          a {
            color: #fff;
          }
        }
      }
      .font-w {
        font-size: 32px;
        margin-right: 30px;
        white-space: nowrap;
      }
    }
  }
  .shopactive {
    border-bottom: 1px solid #2c82ff;
    background-color: #222426;
    color: #fff;
  }
  .tab-cont {
    max-width: 1200px;
    min-height: 1000px;
    margin: 0 auto;
    @media only screen and (max-width: 900px) {
      margin: 20px;
    }
  }
  .main {
    background: #fff;
    .center {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      .centerL {
        width: 85%;
        padding: 20px 0;
        @media only screen and (max-width: 900px) {
          width: 70%;
        }
        p {
          &:nth-child(1) {
            font-size: 24px;
            color: #333333;
          }
          &:nth-child(2) {
            font-size: 18px;
            color: #b3b3b3;
          }
        }
      }
      .centerR {
        width: 15%;
        padding: 20px 0 20px 20px;
        text-align: left;
        background: #252a2f;
        color: #fff;
        @media only screen and (max-width: 900px) {
          width: 30%;
        }
        p {
          &:nth-child(2) {
            font-size: 14px;
          }
        }
        span {
          color: #2c82ff;
          font-size: 30px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
