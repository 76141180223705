<template>
  <div class="record">
    <div class="title">
      {{ $t("累计明细") }}<span>{{ $t("每周更新一次") }}</span>
    </div>
    <div class="table">
      <a-table
        :columns="columns"
        :data-source="pointList"
        size="default"
        :pagination="pagination"
        @change="handleTableChange"
      />
    </div>
  </div>
</template>

<script>
import request from "../../api/request";
import moment from "moment";
export default {
  props: ["choseId"],
  data() {
    return {
      pagination: {
        total: 0,
        pageSize: 10, //每页中显示10条数据
        current: 1, //第几页
      },
      queryParam: {
        page: 1, //第几页
        size: 10, //每页中显示数据的条数
      },
      columns: [
        {
          title: this.$t("日期"),
          dataIndex: "exchange_at",
          customRender: function (val) {
            return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : "";
          },
        },
        {
          title: this.$t("积分累积记录"),
          dataIndex: "product_name",
        },
        {
          title: this.$t("获得积分"),
          dataIndex: "point",
        },
      ],
      pointList: [],
    };
  },
  mounted() {
    this.getPointRecord();
    if (this.choseId == 1) {
      this.getPointRecord();
    }
    if (this.choseId == 2) {
      this.getChangeRecord();
    }
  },
  methods: {
    // 积分记录
    getPointRecord() {
      let _this = this;
      request
        .get("/pointrecords/userlist/", {
          page: _this.queryParam.page,
          limit: _this.queryParam.size,
        })
        .then(function (res) {
          if (res.status == 200) {
            _this.pagination.total = res.data.paging.total;
            _this.pointList = res.data.results;
          } else {
            _this.$message.error(res.detail);
          }
        });
    },
    // 兑换记录
    getChangeRecord() {
      let _this = this;
      request
        .get("/promocodes/?promo_type=1", {
          page: _this.queryParam.page,
          limit: _this.queryParam.size,
        })
        .then(function (res) {
          if (res.status == 200) {
            _this.pagination.total = res.data.paging.total;
            _this.pointList = res.data.results;
          } else {
            _this.$message.error(res.detail);
          }
        });
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.queryParam.size = pagination.pageSize;
      this.getPointRecord();
    },
  },
  watch: {
    choseId(val, oldVal) {
      if (val !== oldVal) {
        if (val == 1) {
          this.getPointRecord();
        }
        if (val == 2) {
          this.getChangeRecord();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.record {
  .title {
    font-size: 20px;
    color: #333333;
    margin: 20px 0;
    span {
      font-size: 16px;
      color: #b3b3b3;
      margin-left: 20px;
    }
  }
  .ant-table-thead > tr > th {
    background: #e6e9f0;
  }
  .ant-table-pagination.ant-pagination {
    float: inherit;
    text-align: center;
    margin: 50px 0;
  }
}
</style>
