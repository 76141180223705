<template>
  <div class="commodity">
    <div class="commodity-title">
      SPECIAL ITEM <span>{{ $t("每周更新一次") }}</span>
    </div>
    <div class="commodity-cont">
      <ul class="commodity-box">
        <li v-for="(item, index) in pointList" :key="index">
          <div class="conL"><img :src="staticUrl + item.cover" alt="" /></div>
          <div class="conR">
            <p>{{ item.name }}</p>
            <p>
              {{ $t("剩余数量") }}<span>{{ item.remain }}</span
              >{{ $t("个") }}
            </p>
            <p>
              <span>{{ originPriceFormat(item.price) }}</span
              >POINT
              <span
                @click="
                  openMask(
                    item.id,
                    item.name,
                    item.price,
                    item.remain,
                    item.cover
                  )
                "
                >{{ $t("兑换") }}</span
              >
            </p>
          </div>
        </li>
      </ul>
      <div class="pagin">
        <a-pagination
          v-show="pointList.length > 0"
          v-model="current"
          :total="total"
          :pageSize="pageSize"
          @change="handleListChange"
          show-less-items
        />
      </div>
    </div>
    <dialogBar
      ref="ModalRejectclick"
      v-model="sendVal"
      type="default"
      title="我是标题"
      content="我是内容"
      v-on:cancel="clickSure()"
      @danger="clickDanger()"
      @confirm="clickConfirm()"
    >
      <span slot="title"> panggame{{ $t("积分商城") }} </span>
      <span slot="content">
        <div class="product-title">
          <p>{{ $t("交换商品") }}</p>
          <p><img src="../../assets/img/logo02.png" alt="" /></p>
        </div>
        <div class="product-name">
          <img :src="staticUrl + pointCover" alt="" />
          &nbsp;
          <span>{{ pointName }}</span>
        </div>
        <ul class="product-point">
          <li>
            <p>{{ $t("交换个数") }}</p>
            <p>
              <button @click="jian()">-</button>
              <span>{{ num }}</span>
              <button @click="jia()">+</button>
            </p>
          </li>
          <li>
            <p>{{ $t("所需积分") }}</p>
            <p>
              <span>{{ originPriceFormat(pointPrice) }}</span
              >point
            </p>
          </li>
          <li>
            <p>{{ $t("我的积分") }}</p>
            <p>
              <span>{{ originPriceFormat(userpoint) }}</span
              >point
            </p>
          </li>
        </ul>
        <div class="product-explain">
          <p>{{ $t("您可以检查优惠券详细信息。") }}</p>
          <p>{{ $t("检查详细信息后，如果有任何问题，请联系客服中心。") }}</p>
        </div>
      </span>
    </dialogBar>
  </div>
</template>

<script>
import dialogBar from "../../components/Dialog";
import request from "../../api/request";
import Bus from "../../utils/bus";
import filters from "../../utils/filters";
export default {
  components: {
    dialogBar,
  },
  data() {
    return {
      staticUrl: request.staticUrl,
      sendVal: false,
      num: 0,
      current: 1,
      total: 1,
      pageSize: 4,
      pointList: [],
      pointId: "",
      pointName: "",
      pointPrice: "",
      pointRemain: "",
      pointCover: "",
      userpoint: "",
    };
  },
  mounted() {
    this.getPoint();
    this.getMe();
  },
  methods: {
    originPriceFormat: filters.priceFormat,
    getPoint() {
      let _this = this;
      request
        .get("/pointproducts", { page: _this.current, limit: _this.pageSize })
        .then(function (res) {
          if (res.status == 200) {
            _this.total = res.data.paging.total;
            _this.pointList = res.data.results;
          } else {
            _this.$message.error(res.detail);
          }
        });
    },
    openMask(id, name, price, remain, cover) {
      let _this = this;
      _this.$refs.ModalRejectclick.showMask = true;
      _this.pointId = id;
      _this.pointName = name;
      _this.pointPrice = price;
      _this.pointRemain = remain;
      _this.pointCover = cover;
    },
    clickSure() {
      let _this = this;
      if (_this.num == 0) {
        return;
      } else {
        let data = {
          count: _this.num,
        };
        request
          .post("/pointproducts/" + _this.pointId + "/exchange/", data)
          .then(function (res) {
            if (res.status == 200) {
              _this.$message.success(_this.$t("兌換成功"));
              _this.getPoint();
              _this.getMe();
              _this.message();
            } else {
              _this.$message.error(res.detail);
            }
          });
      }
    },
    getMe() {
      let _this = this;
      request.get("/users/me", {}, true).then(function (res) {
        if (res.status == 200) {
          if (res.data) {
            _this.userpoint = res.data.member_point;
            _this.$store.commit("setUserInfo", res.data);
          }
        }
      });
    },
    message() {
      Bus.$emit("getuserpoint", "成功");
    },
    jian() {
      if (this.num > 0) {
        this.num--;
      }
    },
    jia() {
      if (this.num < this.pointRemain) {
        this.num++;
      }
    },
    handleListChange(current, pageSize) {
      this.current = current;
      if (pageSize != null && pageSize != "") {
        this.pageSize = pageSize;
      }
      this.getPoint();
    },
  },
};
</script>

<style lang="scss">
.commodity {
  .commodity-title {
    font-size: 20px;
    color: #333333;
    margin: 20px 0;
    span {
      font-size: 16px;
      color: #b3b3b3;
      margin-left: 20px;
    }
  }
  .commodity-box {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 31%;
      margin: 10px;
      display: flex;
      background-color: #fff;
      padding: 10px 5px;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 0px 10px #ccc;
      }
      @media only screen and (max-width: 900px) {
        width: 43%;
        display: block;
      }
      .conL {
        img {
          width: 120px;
          height: 120px;
          margin-right: 5px;
          @media only screen and (max-width: 900px) {
            width: 100%;
            height: auto;
          }
        }
      }
      .conR {
        p {
          white-space: nowrap;
          margin: 5px 0;
          &:nth-child(1) {
            color: #333333;
            font-size: 20px;
          }
          &:nth-child(2) {
            color: #b3b3b3;
            font-size: 14px;
            span {
              color: #d48d05;
              display: inline-block;
              margin: 0 2px;
            }
          }
          &:nth-child(3) {
            color: #b3b3b3;
            font-size: 14px;
            span {
              &:nth-child(1) {
                font-size: 28px;
                color: #d48d05;
                display: inline-block;
                margin: 0 2px;
              }
              &:nth-child(2) {
                font-size: 12px;
                color: #fff;
                background-color: #d48d05;
                border-radius: 40px;
                display: inline-block;
                padding: 2px 5px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .pagin {
    text-align: center;
    margin: 50px 0;
  }
}
.product-title {
  display: flex;
  padding: 10px 20px;
  p {
    flex-grow: 1;
    img {
      width: 100px;
      height: auto;
    }
    &:nth-child(1) {
      font-size: 24px;
      color: #333333;
      line-height: 50px;
    }
    &:nth-child(2) {
      text-align: right;
    }
  }
}
.product-name {
  padding: 10px 20px;
  img {
    width: 50px;
  }
  span {
    font-size: 24px;
    color: #333333;
  }
}
.product-point {
  color: #999999;
  font-size: 16px;
  padding: 10px 20px;
  li {
    display: flex;
    margin: 20px 0;
    &:nth-child(1) {
      p {
        &:nth-child(2) {
          span {
            color: #222;
            display: inline-block;
            margin: 0 5px;
          }
        }
      }
      button {
        background-color: #ececec;
        color: #222;
      }
    }
    p {
      flex-grow: 1;
      &:nth-child(2) {
        text-align: right;
      }
      span {
        color: #d48d05;
        font-size: 22px;
        margin-right: 5px;
      }
    }
  }
}
.product-explain {
  padding: 10px 20px;
  color: #b3b3b3;
  font-size: 14px;
}
</style>
